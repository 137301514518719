import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

import Viewsapling from 'components/register-plantation/viewsapling';

// import Viewthubnailslider from 'components/register-plantation/viewthumbnail-slider';

// markup
const ViewSapling = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness | View Sapling' />
      <Layout>
        <section id='viewsapling'>
          <Viewsapling />
        </section>

        {/* <section id='Viewthubnailslider'>
          <Viewthubnailslider />
        </section>   */}
      </Layout>
    </>
  );
};

export default ViewSapling;

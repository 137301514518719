import React from 'react';

import 'assets/styles/pages/plantationregister.scss';

import 'assets/styles/pages/plantationgrid.scss';

import mulspeciestree from 'assets/images/register-plantation/multiple-species-tree.png';

import Carousel from 'react-multi-carousel';

import Arrowforward from 'assets/images/icons/arrow-forward.png';

import Arrowleftnext from 'assets/images/icons/arrow-left-next.png';

export default function Viewsaplingtreecarosel(props) {
  const loadData = props.item

  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 2,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
    },
  };
  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow }
    } = rest;

    return (
      <div className='carousel-button-group'>
        <div className='navigations'>
          <button className='right-butt-custom' onClick={() => previous()}>
            <img src={Arrowleftnext} alt='LeftArrowforward' />
          </button>
          <button className='left-butt-cutom' onClick={() => next()}>
            <img src={Arrowforward} alt='LeftArrowforward' />
          </button>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className='multiple-species-wrapper'>
        <h3 className='iniativecard-title'>Multiple Species </h3>
        <div className='row'>
          <div className='intro-carousel2' id="carousel-bg">
            <Carousel
              arrows={true}
              minimumTouchDrag={80}
              partialVisible
              renderButtonGroupOutside={false}
              responsive={responsive}
            // customButtonGroup={<ButtonGroup
            //   next={props.next}
            //   previous={props.previous}
            //   rest={props.rest} />}
            >
              {loadData.map(item => {
                return <div className='col-md-6 multiple-species-box-wrapper' key={item.id}>
                  <div className='dis-flex icon-species-text'>
                    <span className='mulspecies-tree'>
                      <img src={mulspeciestree} alt='treeRightIconGreen' />
                    </span>

                    <div className='margin-top-15'>
                      <div className='surving-item'> Your <b>{item.species.speciesName}</b> Tree </div>
                      <p class="text-city tree-count"> {item.aliveCount} Trees</p>
                    </div>
                  </div>
                  <div className='list-species-item'>
                    <div className='wid-50'>
                      <div className='surving-item'> Species surviving count</div>
                      <div className='surving-number-item'> {item.aliveCount} </div>
                      <div className='surving-item'> Species not surviving count </div>
                      <div className='surving-number-item'> {item.deadCount ? item.deadCount : 0}</div>
                      <div className='surving-item'> Species replaced with new </div>
                      <div className='surving-number-item'> {item.replacedCount ? item.replacedCount : 0} </div>
                    </div>
                    <div className='wid-50'>
                      <div className='surving-item'> Height (cm) </div>
                      <div className='surving-number-item'>  {item.growthStatus ? item.growthStatus.attributeMap.height : 0} </div>
                      <div className='surving-item'> Girth / DBH (cm) </div>
                      <div className='surving-number-item'>  {item.growthStatus ? item.growthStatus.attributeMap.girth : 0} </div>
                    </div>
                  </div>
                </div>
              })}
            </Carousel>
          </div>
        </div>
      </div>


    </>
  );
}

import React, { useState, useEffect } from 'react';
import { useQueryParam, NumberParam } from "use-query-params";
import Carousel from 'react-multi-carousel';

import 'assets/styles/pages/plantationregister.scss';

import 'assets/styles/pages/plantationgrid.scss';

import Plantationbreadcumb from 'components/register-plantation/plantationbread';

import Viewsaplingtreecarosel from 'components/register-plantation/viewsapling-carousel';

import eyeicon from 'assets/images/admin/visibility.png';

import Arrowforward from 'assets/images/icons/arrow-forward.png';

import Arrowleftnext from 'assets/images/icons/arrow-left-next.png';

import plannedby from 'assets/images/icons/planned-by.png';
import plantedOn from 'assets/images/admin/plantedOn.svg';
import plantedBy from 'assets/images/admin/plantedBy.png';
import stateicon from 'assets/images/admin/stateicon.svg';
import village from 'assets/images/admin/village.svg';
import 'react-multi-carousel/lib/styles.css';

import PlantationListDetails from 'services/plantation/plantation.service';
import { Modal, Spinner } from 'react-bootstrap';
import SaplingThumbnail from './saplingthumbnail-slider';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { doLogout } from 'components/Auth';
import noimage from "assets/images/noImage.png";

import editiconpencil from 'assets/images/admin/edit-icon.svg';

import mapIcon from 'assets/images/admin/location.png'

import Googlemaps from 'components/map-initiative/googlemaps';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import { navigate } from 'gatsby';

export function Viewsapling() {
  const [saplingId] = useQueryParam("id", NumberParam);
  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 2,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 1,
      //partialVisibilityGutter: 150,
    },
  };
  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow }
    } = rest;
    return (
      <div className='carousel-button-group'>
        {/* <div className='navigations'>
          <button className="right-butt-custom" onClick={() => previous()}>
            {currentSlide !== 0 ? <img src={Arrowleftnext} alt='LeftArrowforward' /> : null}
          </button>
          <button className={totalItems > 3 ? "" : 'left-butt-cutom'} onClick={() => next()}>
            {totalItems > 3 ? <img src={Arrowforward} alt='LeftArrowforward' /> : null}
          </button>
        </div> */}
        <div className='navigations'>
          <button className='right-butt-custom' onClick={() => previous()}>
            <img src={Arrowleftnext} alt='LeftArrowforward' />
          </button>
          <button className='left-butt-cutom' onClick={() => next()}>
            <img src={Arrowforward} alt='LeftArrowforward' />
          </button>
        </div>
      </div>
    );
  };
  const ds = new PlantationListDetails();


  const [plantationList, setPlantationList] = useState("");
  const [loading, setLoading] = useState(false);
  const [detailsShown, setDetailsShown] = useState(false);
  const [saplingData, setSaplingData] = useState("");
  const [loadViewData, setLoadViewData] = useState("");

  const [geoLocationOpen, setGeoLocationOpen] = useState(false);
  const [locationValue, setLocationValue] = useState(false);

  const geoLocation = async (data) => {
    setGeoLocationOpen(true)
    let values = {
      latitude: data.latitude,
      longitude: data.longitude,
    }
    setLocationValue(values)
  }

  const listPlanters = async () => {
    setLoading(true);
    const plantationResponse = await ds.listSaplings();
    setPlantationList(...plantationList, plantationResponse.data.trackingDetails);
    setSaplingData(...saplingData, plantationResponse.data);
    setLoading(false);
  };

  const loadData = (e) => {
    setLoadViewData(e)
    setDetailsShown(true)
  }
  const closeData = () => {
    setDetailsShown(false)
    window.scrollTo(0, 0)
  }
  const loadSaplingData = async (id) => {
    setLoading(true)
    await ds.listSpeicess(id)
      .then(data => {
        if (data.status === 200) {
          setPlantationList(...plantationList, data.data.trackingDetails);
          setSaplingData(...saplingData, data.data);
          setLoading(false)
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 404) {
            toast.error("Authentication failed");
            setTimeout(function () {
              doLogout()
              navigate("/")
              setLoading(false);
            }, 3000)
          } else {
            toast.error(error.response.data.message);
          }
        }
      }
      );
  };
  useEffect(() => {
    loadSaplingData(saplingId)
  }, []);
  return (
    <>
      <div>
        <Plantationbreadcumb backTo="/planter-details" name="View Sapling" />
      </div>
      <div className='plantation-breadsection'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 padding-mobile'>
              <div className='title-inline'>
                <h3 className='title-item view-pro-name'> {saplingData && saplingData.project !== "" ? <> {saplingData.project.name}</> : ""} </h3>
                <h3 className='bred-regid view-pro-name'> Reg Id #{saplingData && saplingData !== "" ? <> {saplingData.registrationId}</> : ""} </h3>
                <h5 className='bred-datetime'>{saplingData && saplingData !== "" ? <>  {moment(new Date(saplingData.plantationDate)).format(("ddd, MMM D YYYY"))}</> : ""} </h5>
              </div>

              <div className='dis-in-planed'>
                <span className='dis-image-item'>
                  <img src={plantedOn} alt='treeRightIconGreen' />
                </span>

                <h5 className='planed-text'>Planted On:</h5>
                <h5 className='date-calc-text'>{saplingData && moment(new Date(saplingData.plantationDate)).format(("ddd, MMM D YYYY"))}</h5>
              </div>

              <div className='dis-in-planed'>
                <span className='dis-image-item'>
                  <img src={plantedBy} alt='treeRightIconGreen' />
                </span>

                <h5 className='planed-text'>Planted By:</h5>
                <h5 className='date-calc-text'> {saplingData.planterName !== "" ? saplingData.planterName : "No Name"}</h5>
              </div>

              <div className='dis-in-planed'>
                <span className='dis-image-item'>
                  <img src={village} alt='treeRightIconGreen' />
                </span>

                <h5 className='planed-text'>Village/city </h5>
                <h5 className='date-calc-text'>{saplingData && saplingData.attributeMap.city}</h5>
              </div>

              <div className='dis-in-planed'>
                <span className='dis-image-item'>
                  <img src={stateicon} alt='treeRightIconGreen' />
                </span>

                <h5 className='planed-text'>State: </h5>
                <h5 className='date-calc-text'> {saplingData && saplingData.attributeMap.state}</h5>
              </div>
            </div>
          </div>
          {plantationList && plantationList.length > 0 ?
            <div className='row mg-top20'>
              <div className='carousel-section' id="carousel-bg" >
                <Carousel
                  arrows={true}
                  minimumTouchDrag={80}
                  partialVisible
                  renderButtonGroupOutside={false}
                  swipeable
                  responsive={responsive}
                // customButtonGroup={<ButtonGroup />}
                >
                  {plantationList && plantationList.map((item) => {
                    const count = (item.trackingSpeciesDetails && item.trackingSpeciesDetails) ? item.trackingSpeciesDetails.reduce((counter, obj) => counter += obj.speciesCount, 0) : 0; // 6

                    return (
                      <div className='iniative-card hand-pointer' key={item.id} data-value={item.id}>
                        <div className='wid-55' >
                          {item.imagesLogs && item.imagesLogs ?
                            <SaplingThumbnail images={item.imagesLogs} />
                            : <img src={noimage} alt="default Image" />}
                        </div>

                        <div className='iniativecard-body wid-45' id="card-val" >
                          <div className='text-right icon-rightposi icon-rightposiss pad-5'>
                            <img src={mapIcon} className='img-fluid hand-pointer' width="20px" height="20px" alt='Edit Icon' onClick={() => geoLocation(item)} /> &nbsp;
                            <img className='img-fluid hand-pointer' id="eyeicon" src={eyeicon} alt='bellicon' onClick={() => loadData(item)} />
                          </div>
                          <p className='text-city'>Tracking Id </p>
                          <p className='text-city'><b># {item.trackingId}
                          </b>
                          </p>
                          <p className='text-city'>


                          </p>
                          <p className='text-city tree-count'>{count} Trees</p>
                          {/* <div className='plant-type'>Plant Type</div>
                          <p className='text-city'><b>
                            {truncate(item.plantType, {
                              length: 100,
                              omission: '...',
                            })}
                          </b>
                          </p> */}
                          <div className='plant-type'>Spices</div>
                          {item.trackingSpeciesDetails && item.trackingSpeciesDetails.map((data, index) => {
                            return <b><span className='text-city' key={index + 1}>{data.speciesCount} {data.species.speciesName} {index === item.trackingSpeciesDetails.length - 1 ? "" : ", "}</span></b>
                          })}

                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div> : null}
          {detailsShown ?
            <div className='row'>
              <div className='col-md-12 mg-top30'>
                <h3 className='iniativecard-title'>IMAGES </h3>

                <div className='dis-flex add-more-img-posi'>
                  {/* {loadViewData && loadViewData.imagesLogs.map(item => {
                    return <img src={item.imageUrl} key={item.id} width="150px" height="150px" className='img-fluid' alt='Add image' />
                  })} */}

                  <SimpleReactLightbox>
                    <SRLWrapper>

                      {loadViewData && loadViewData.imagesLogs.map(data => {
                        return (
                          <div className='recent-plantation-section-plantation'>
                            <div className='img-wrapper img_wrp hand-pointer' key={data}>
                              <img src={data.imageUrl} className='img-fluid' alt='' width="150px" height="150px" />
                              <div className='overlay overLay-img'>
                                <i className='icon icon-arrows-fullscreen'></i>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </SRLWrapper>
                  </SimpleReactLightbox>
                </div>
              </div>

              <div className='col-md-12'>
                <br />
                <Viewsaplingtreecarosel item={loadViewData.trackingSpeciesDetails} />

                <div className='text-right mg-top20 display-button-order align_center-card'>
                  <button className='btn btn-approve save-width posi-close-top align_center-card' onClick={closeData}> Close </button>
                </div>
              </div>
            </div> : null}
        </div>
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <Modal
        show={geoLocationOpen}
        id="mappop"
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        className='tracklocation-popup'
        onHide={() => setGeoLocationOpen(false)}


        centered>
        <Modal.Header closeButton='close-button topright'></Modal.Header>
        <Modal.Body id="mappop">
          <div id="mappop" className='map-page plantpopup-page'>
            <Googlemaps mapValues={{ 'mapValues': locationValue }} />
            <div className='container'>
              <div className='row'>

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
function mapStateToProps(state) {
  return {
    viewData: state.appDetails.viewSapling,
  };

}
export default connect(mapStateToProps)(Viewsapling)     
